import React, { useContext, useEffect, useState } from 'react';
import FunnelContext from '../../context/FunnelContext';
import * as S from './BumpOfferScrollPDP2023.styles';
import 'react-responsive-carousel/lib/styles/carousel.css';
import { formatPrice } from '../../utils/formatPrice';
import { Image as BuilderImage } from '@builder.io/react';

const BumpOfferScrollPDP2023Item = props => {
  const {
    index,
    item,
    mobileCards,
    minHeightDesktop = 130,
    minHeightMobile = 190,
    carouselContainerPercentage = 70,
    offers = []
  } = props;

  const {
    currency,
    pageDesign,
    bumpOfferArray,
    setBumpOfferArray
  } = useContext(FunnelContext);

  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    setIsSelected(bumpOfferArray.includes(index));
  }, [index, bumpOfferArray, setIsSelected]);

  const handleButton = index => {
    console.log(index);
    setBumpOfferArray(prev => {
      if (prev.includes(index)) {
        setIsSelected(false);
        return bumpOfferArray?.filter(item => item !== index);
      } else {
        setIsSelected(true);
        return [...bumpOfferArray, index];
      }
    });
  };

  const priceSettings = { currencySymbol: currency?.symbol || '$' };

  const { shortDescription = 'change description here', mainImage } =
    offers[index] || {};

  return (
    <S.BumpOffer
      carouselContainerPercentage={carouselContainerPercentage}
      key={item?.checkoutData?.variant_id}
      onClick={() => handleButton(index)}
    >
      <S.BumpOfferItem
        key={item?.checkoutData?.variant_id}
        mobileCards={mobileCards}
        selected={isSelected}
        minHeightDesktop={minHeightDesktop}
        minHeightMobile={minHeightMobile}
      >
        <S.BumpOfferImage mobileCards={mobileCards} pageDesign={pageDesign}>
          <BuilderImage image={mainImage} alt={item?.checkoutData.title} />
        </S.BumpOfferImage>
        <S.Description>
          <S.Checkbox
            selected={isSelected}
            mobileCards={mobileCards}
            className={isSelected ? 'active' : ''}
          ></S.Checkbox>
          <S.ProductDetails>
            <S.DisplayTitle selected={isSelected} mobileCards={mobileCards}>
              {item?.display_title}
            </S.DisplayTitle>
            <S.DisplayDesc>{shortDescription}</S.DisplayDesc>
          </S.ProductDetails>

          <S.Row>
            <S.Price>
              <S.FullPrice>
                {formatPrice(item?.checkoutData?.price, priceSettings)}
              </S.FullPrice>
              <S.DiscountedPrice>
                {formatPrice(
                  item?.checkoutData?.discounted_price,
                  priceSettings
                )}
              </S.DiscountedPrice>
            </S.Price>
            <S.DiscountPercentage>
              <div>
                <span>{item?.discount_percentage}% Off</span>
              </div>
            </S.DiscountPercentage>
          </S.Row>
        </S.Description>
      </S.BumpOfferItem>
    </S.BumpOffer>
  );
};

export default BumpOfferScrollPDP2023Item;
