import React, { useContext } from 'react';
import FunnelContext from '../../context/FunnelContext';
import * as S from './BumpOfferScrollPDP2023.styles';
import 'react-responsive-carousel/lib/styles/carousel.css';
import { Carousel } from 'react-responsive-carousel';
import BumpOfferScrollPDP2023Item from './BumpOfferScrollPDP2023Item';

const BumpOfferScroll = props => {
  const {
    showTitle,
    otpTitle,
    subTitle,
    forceCurrentCategory = false,
    forceCurrentCategoryValue = 'subscriptions',
    carouselContainerPercentage = 75
  } = props;

  const { onetime, subscription, currentCategory } = useContext(FunnelContext);

  const finalCategory = forceCurrentCategory
    ? forceCurrentCategoryValue
    : currentCategory;

  const bumpoffers =
    finalCategory === `onetime`
      ? onetime?.bumpoffers
      : subscription?.bumpoffers;

  return (
    <S.BumpOfferWrapper>
      {showTitle ? (
        <>
          {finalCategory === `subscriptions` ? (
            <S.ComponentTitle>{subTitle}</S.ComponentTitle>
          ) : (
            <S.ComponentTitle>{otpTitle}</S.ComponentTitle>
          )}
        </>
      ) : (
        <></>
      )}
      <Carousel
        showThumbs={false}
        showStatus={false}
        showArrows={false}
        swipeable={true}
        centerMode={true}
        axis="horizontal"
        centerSlidePercentage={carouselContainerPercentage}
        emulateTouch={true}
      >
        {bumpoffers.map((item, index) => {
          return (
            <BumpOfferScrollPDP2023Item item={item} index={index} {...props} />
          );
        })}
      </Carousel>
    </S.BumpOfferWrapper>
  );
};

export default BumpOfferScroll;
